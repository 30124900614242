import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import { isMobile } from "utils/userAgent";
import { BlueCard } from "components/Card";
import Bitcoin from "assets/images/home/bitcoin.png"
import Sandglass from "assets/images/home/sandglass.png"
import Mining from "assets/images/home/mining.png"
import Tick from "assets/images/home/tick.png"
import People from "assets/images/home/people.png"
import Row from "components/Row";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  gap: 3rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
  `};
`;

const BodyText = styled(ThemedText.MediumHeader)`
  width: 279px;
  height: 150px;
  margin-left: 3rem;
  margin-top: 1rem;
  padding: 16px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    text-align: center;
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-top: 0;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    text-align: center;
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-top: 0;
  `};
`;

const Image = styled.img`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 95px;
    height: 95px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 95px;
    height: 95px;
  `};
`;

export function Objectives() {
  return (
    <Container id={"objectives"}>
      <ThemedText.Title color="primary1" textAlign={isMobile ? "center" : "left"}>
        <Trans>Objectives</Trans>
      </ThemedText.Title>
      <BlueCard width={isMobile ? "100%" : "372px"} height={isMobile ? "100%" : "367px"}>
        <Row justify={isMobile ? "center" : "flex-end"}>
          <Image src={Bitcoin} alt="bitcoin-icon" />
        </Row>
        <BodyText>
          <Trans>
            The objective is to democratize mining by opening the
            possibility to investors so that they can mine Bitcoin
          </Trans>
          .
        </BodyText>
      </BlueCard>
      <BlueCard width={isMobile ? "100%" : "372px"} height={isMobile ? "100%" : "367px"}>
        <Row justify={isMobile ? "center" : "flex-end"}>
          <Image src={Sandglass} alt="sandglass-icon" />
        </Row>
        <BodyText>
          <Trans>
            Transforming the global financial ecosystem for a more
            equitable and sustainable world
          </Trans>
          .
        </BodyText>
      </BlueCard>
      <BlueCard width={isMobile ? "100%" : "372px"} height={isMobile ? "100%" : "367px"}>
        <Row justify={isMobile ? "center" : "flex-end"}>
          <Image src={Mining} alt="mining-icon" />
        </Row>
        <BodyText>
          <Trans>
            At GreenBlock we have studied the market so we know how and
            where to mine
          </Trans>
          .
        </BodyText>
      </BlueCard>
      <BlueCard width={isMobile ? "100%" : "372px"} height={isMobile ? "100%" : "367px"}>
        <Row justify={isMobile ? "center" : "flex-end"}>
          <Image src={Tick} alt="tick-icon" />
        </Row>
        <BodyText>
          <Trans>
            We also care about the environment as well as the bad image of
            mining. for energy consumption
          </Trans>
          .
        </BodyText>
      </BlueCard>
      <BlueCard width={isMobile ? "100%" : "372px"} height={isMobile ? "100%" : "367px"}>
        <Row justify={isMobile ? "center" : "flex-end"}>
          <Image src={People} alt="people-icon" />
        </Row>
        <BodyText>
          <Trans>
            From Green Block Capital we guarantee commitment, respect,
            veracity and transparency
          </Trans>
          .
        </BodyText>
      </BlueCard>
    </Container>
  );
}
