import useScrollPosition from "@react-hook/window-scroll";
import styled from "styled-components/macro";
import { useActiveLocale } from "../../hooks/useActiveLocale";
/* import { ButtonExternalLinkEmpty } from "../Link";
import ColorToggle from "../Toggle/ColorToggle"; */
import {
  LOCALE_LABEL,
  SUPPORTED_LOCALES,
  SupportedLocale,
} from "../../constants/locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faCheck,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useLocationLinkProps } from "../../hooks/useLocationLinkProps";
import MenuMobile from "./MenuMobile";
import { isMobile } from "utils/userAgent";
import Menu from "./Menu";
import Logo from "assets/images/logo-title-white.svg";
import LogoData from "assets/images/data-logo.svg";
import MenuData from "./components/MenuData";
import MenuDataMobile from "./components/MenuDataMobile";

export enum FlyoutAlignment {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 120px 1fr 10px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  position: relative;
  padding: 1rem;
  z-index: 21;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) =>
    showBackground ? "0 -100%" : "0 0"};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px
    ${({ theme, showBackground }) =>
    showBackground ? theme.bg2 : "transparent;"};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 48px 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 1rem;
    grid-template-columns: 48px 1fr;
  `};
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`;

const InternalMenuItem = styled(Link)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`;

const InternalLinkMenuItem = styled(InternalMenuItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.primary1};
    cursor: pointer;
    text-decoration: none;
  }
`;

const MenuFlyout = styled.span<{ flyoutAlignment?: FlyoutAlignment }>`
  min-width: 150px;
  max-height: 350px;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  z-index: 99;
  padding: 12px;
  gap: 0.5rem;
  top: 80%;
`;

/* const MenuChain = styled.span<{ flyoutAlignment?: FlyoutAlignment }>`
  min-width: 100px;
  max-height: 350px;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  z-index: 99;
  padding: 12px;
  gap: 0.5rem;
  top: 80%;
`; */

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding-right: 5rem;
`;

const ToggleMenuItem = styled.button`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.2rem 0.2rem;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`;

const GlobeIcon = styled.div<{ data: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background: ${({ theme, data }) => data ? theme.primaryData5 : theme.primary5};
  border-radius: 50%;
`;



function LanguageMenuItem({
  locale,
  active,
}: {
  locale: SupportedLocale;
  active: boolean;
}) {
  const { to, onClick } = useLocationLinkProps(locale);

  if (!to) return null;

  return (
    <InternalLinkMenuItem onClick={onClick} to={to}>
      <div style={{ color: active ? "#39AF45" : "" }}>{LOCALE_LABEL[locale]}</div>
      {active && <FontAwesomeIcon icon={faCheck} width={16} color={active ? "#39AF45" : ""} />}
    </InternalLinkMenuItem>
  );
}

function AddMenu(path: string) {
  if (path.startsWith("/data")) return <MenuData />;
  return (
    <Menu />
  );
}

function AddMenuMobile(path: string) {
  if (path.startsWith("/data")) return <MenuDataMobile />;
  return (
    <MenuMobile />
  );
}

export default function Header() {
  const { pathname } = useLocation();
  const activeLocale = useActiveLocale();
  const scrollY = useScrollPosition();

  const [open, setOpen] = useState(false);
  return (
    <HeaderFrame showBackground={scrollY > 45}>
      {pathname.startsWith("/data") ?
        <img src={LogoData} alt="data-logo" width={isMobile ? 152 : 231} height={isMobile ? 45 : 68} />
        :
        <img src={Logo} alt="gbc-logo" width={isMobile ? 160 : 224} height={isMobile ? 61 : 86} />
      }
      {!isMobile && AddMenu(pathname)}
      <HeaderControls>
        <Column>
          <HeaderElement onClick={() => setOpen(!open)}>
            <GlobeIcon data={pathname.startsWith("/data")}>
              <FontAwesomeIcon icon={faGlobe} color="#fff" width={24} />
            </GlobeIcon>
          </HeaderElement>
          {open && (
            <MenuFlyout
              onMouseLeave={() => setOpen(false)}
              onClick={() => setOpen(!open)}
            >
              <ToggleMenuItem onClick={() => setOpen(false)}>
                <FontAwesomeIcon icon={faChevronLeft} width={12} />
              </ToggleMenuItem>
              {SUPPORTED_LOCALES.map((locale, index) => (
                <LanguageMenuItem
                  locale={locale}
                  active={activeLocale === locale}
                  key={locale}
                />
              ))}
            </MenuFlyout>
          )}

        </Column>
        {isMobile && AddMenuMobile(pathname)}

        {/* <HeaderElement>
          <ColorToggle
            id="toggle-color-mode"
            isActive={darkMode}
            toggle={() => toggleDarkMode()}
          />
        </HeaderElement> */}
        {/* <Column>
          <HeaderElement>
            <ButtonPrimary
              fontSize={isMobile ? 14 : 16}
              size="10px"
            >
              <ButtonExternalLinkEmpty
                href="https://app.greenblockcapital.io"
                target="_blank"
                rel="noopener noreferrer">
                <Trans>Launch App</Trans>
              </ButtonExternalLinkEmpty>
            </ButtonPrimary>
          </HeaderElement>
          {openChain && (
            <MenuChain
              onMouseLeave={() => setOpenChain(false)}
              onClick={() => setOpenChain(!openChain)}
            >
              <ToggleMenuItem onClick={() => setOpenChain(false)}>
                <FontAwesomeIcon icon={faChevronLeft} width={12} />
              </ToggleMenuItem>
              <Row>
                <ButtonExternalLinkEmpty
                  href="https://app.greenblockcapital.io/home?chain=binance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Binance</Trans>
                </ButtonExternalLinkEmpty>
                <img src={BSC} alt="bsc-icon" width={24} />
              </Row>
              <Row>
                <ButtonExternalLinkEmpty
                  href="https://app.greenblockcapital.io/home?chain=polygon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Polygon</Trans>
                </ButtonExternalLinkEmpty>
                <img src={Polygon} alt="bsc-icon" width={24} />
              </Row>
            </MenuChain>
          )}
        </Column> */}
      </HeaderControls>
    </HeaderFrame>
  );
}
