import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { AutoColumn } from "../../components/Column";
import YouTube, { YouTubeProps } from "react-youtube";
import { Datacenter as Datacomp } from "./components/Datacenter";
import { Gallery } from "./components/Gallery";
import { isMobile } from "utils/userAgent";

const Container = styled(AutoColumn)`
  margin-top: 5rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

const Box = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 5rem;
  background: #030315;
  padding: 10px;
  border-radius: 16px;
`;

export function Datacenter() {

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: '390',
    width: isMobile ? '300' : '800',
  };

  return (
    <Container id={"host"}>
      <Header>
        <ThemedText.Title
          color="primary1"
          textAlign="center"
        >
          <Trans>Host</Trans>
        </ThemedText.Title>
        <ThemedText.Body textAlign="center">
          <Trans>
            Over last years the United Arab Emirates has become one of the friendliest countries for cryptocurrency trading and blockchain technology development,
            initially driven by Dubai becoming one of the most important blockchain hubs in the world, but as well closely followed by Abu Dhabi and other estates.
            UAE is investing a huge amount of energy and money to tackle cryptocurrency expansion in the future. The rapid growth of cryptocurrency companies has
            completely transformed the global economy, encouraging </Trans><span style={{ color: '#39AF45' }}> GreenBlock Capital</span> <Trans>to start up crypto mining farms in the UAE</Trans>

          .
        </ThemedText.Body>
      </Header>
      <Datacomp />
      <Gallery />
      <Box>
        <YouTube videoId="NZaGfHvWTqo" opts={opts} onReady={onPlayerReady} />
      </Box>
    </Container>
  );
}
