import { createSlice } from '@reduxjs/toolkit'
import { SupportedLocale } from '../../constants/locales'

export interface UserState {
  matchesDarkMode: boolean // whether the dark mode media query matches

  userDarkMode: boolean | null // the user's choice for dark mode or light mode
  userLocale: SupportedLocale | null
}

export const initialState: UserState = {
  matchesDarkMode: false,
  userDarkMode: true,
  userLocale: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserDarkMode(state, action) {
      state.userDarkMode = action.payload.userDarkMode
    },
    updateMatchesDarkMode(state, action) {
      state.matchesDarkMode = action.payload.matchesDarkMode
    },
    updateUserLocale(state, action) {
      state.userLocale = action.payload.userLocale
    },
  },
})

export const {
  updateMatchesDarkMode,
  updateUserDarkMode,
  updateUserLocale,
} = userSlice.actions
export default userSlice.reducer
