import 'react-multi-carousel/lib/styles.css'
import styled from "styled-components/macro";

import Image1 from "assets/images/datacenter/datacenter1.jpeg";
import Image2 from "assets/images/datacenter/datacenter2.jpeg";
import Image3 from "assets/images/datacenter/datacenter3.jpeg";
import Image4 from "assets/images/datacenter/datacenter4.jpeg";
import Image5 from "assets/images/datacenter/datacenter5.jpeg";
import Image6 from "assets/images/datacenter/datacenter6.jpeg";

import Carousel, { DotProps } from 'react-multi-carousel'

const NftBox = styled.div`
  display: grid;
  place-items: center;
`

const NftImg = styled.img`
  width: 820px;
  height: 450px;
  border-radius: 24px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 300px;  
    height: 200px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 300px;
    height: 200px;
  `};
`


const CustomDot = styled.div<DotProps>`
  width: 16px;
  height: 16px;
  background-color: ${({ active }) => (active ? '#39AF45' : '#fff')};
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
`;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

export function Gallery() {
  return (
    <Carousel
      responsive={responsive}
      infinite
      arrows={false}
      swipeable
      autoPlay
      autoPlaySpeed={3000}
      customTransition="transform 2000ms linear"
      transitionDuration={1000}
      showDots={true}
      customDot={<CustomDot />}
    >
      <NftBox>
        <NftImg src={Image1} />
      </NftBox>
      <NftBox>
        <NftImg src={Image2} />
      </NftBox>
      <NftBox>
        <NftImg src={Image3} />
      </NftBox>
      <NftBox>
        <NftImg src={Image4} />
      </NftBox>
      <NftBox>
        <NftImg src={Image5} />
      </NftBox>
      <NftBox>
        <NftImg src={Image6} />
      </NftBox>
    </Carousel>
  );
}
