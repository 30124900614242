import styled from "styled-components/macro";


import Image2 from "assets/images/events/event2-1.png";
import Image3 from "assets/images/events/event2-3.png";
import Image4 from "assets/images/events/event2-4.png";
import Image5 from "assets/images/events/event2-5.png";
import Image6 from "assets/images/events/event2-6.png";
import Image7 from "assets/images/events/event2-7.png";
import Image8 from "assets/images/events/event2-8.png";

import ReactSimplyCarousel from 'react-simply-carousel';
import { useState } from 'react';
import Carousel from "components/Carousel/Carousel";
import { isMobile } from "utils/userAgent";

const NftImg = styled.img`
  width: 538px;
  height: 538px;
  border-radius: 24px;
  
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 300px;
    height: 300px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 300px;
    height: 300px;
  `};
`

const Container = styled.div`
  width: 70%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`;

export function Gallery2() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const imagesMobile = [Image8, Image3, Image4, Image5, Image6, Image7]
  return (

    <Container>
      {isMobile ? <Carousel images={imagesMobile} /> : <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}

        forwardBtnProps={{
          style: {
            alignSelf: 'center',
            background: 'white',
            border: 'none',
            borderRadius: '50%',
            color: '#39AF45',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
            marginLeft: '2rem',
          },
          children: <div style={{ marginBottom: '2px' }}>{`→`}</div>,
        }}
        backwardBtnProps={{
          style: {
            alignSelf: 'center',
            background: 'white',
            border: 'none',
            borderRadius: '50%',
            color: '#39AF45',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
            marginRight: '2rem',
          },
          children: <div style={{ marginBottom: '2px' }}>{`←`}</div>,
        }}
        responsiveProps={[
          {
            itemsToShow: 1,
            itemsToScroll: 1,
          },
        ]}
        speed={400}
        easing="linear"
      >

        <NftImg src={Image8} alt="" />
        <NftImg src={Image3} alt="" />
        <NftImg src={Image4} alt="" />
        <NftImg src={Image5} alt="" />
        <NftImg src={Image6} alt="" />
        <NftImg src={Image7} alt="" />
      </ReactSimplyCarousel>}

    </Container>
  );
}
