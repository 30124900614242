import { darken } from "polished";
import styled from "styled-components/macro";

export const BaseButtonExternalLink = styled.a<{
  padding?: string;
  width?: string;
  $borderRadius?: string;
  altDisabledStyle?: boolean;
}>`
  padding: ${({ padding }) => padding ?? "16px"};
  width: ${({ width }) => width ?? "100%"};
  height: fit-content;
  font-weight: 500;
  text-align: center;
  border-radius: ${({ $borderRadius }) => $borderRadius ?? "20px"};
  outline: none;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    opacity: 50%;
    cursor: auto;
    pointer-events: none;
  }

  will-change: transform;
  transition: transform 450ms ease;
  transform: perspective(1px) translateZ(0);

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`;

export const ButtonExternalLinkPrimary = styled(BaseButtonExternalLink)`
  background: linear-gradient(90deg, rgba(80,191,91,1) 0%, rgba(87,144,92,1) 100%);
  color: ${({ theme }) => theme.text1};
`;


export const ButtonExternalLinkSecondary = styled(BaseButtonExternalLink)`
  background: transparent;
  border: 1px solid #39AF45;
  border-radius: 16px;
  color: ${({ theme }) => theme.text1};
`;

export const ButtonExternalLinkEmpty = styled(BaseButtonExternalLink)`
  width: fit-content;
  background-color: transparent;
  color: ${({ theme }) => theme.text1};
  &:focus {
    color: ${({ theme }) => theme.primary1};
  }
  &:hover {
    color: ${({ theme }) => theme.primary1};
  }
  &:active {
    color: ${({ theme }) => theme.primary1};
  }
`;


export const ButtonExternalLinkData = styled(BaseButtonExternalLink)`
  background: ${({ theme }) => theme.primaryData1};
  color: ${({ theme }) => theme.textPrimary1};
  border-radius: 10px;
`;
