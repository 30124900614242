import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { isMobile } from "utils/userAgent";
import { AutoColumn } from "../../components/Column";
import { Gallery } from "./components/Gallery";
import { Gallery2 } from "./components/Gallery2";

const Container = styled(AutoColumn)`
  margin-top: 5rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 2rem;
  `};
`;

const Column = styled.div`
  width: 900px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 100%;
  `};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

export function Events() {

  return (
    <Container id={"events"}>
      <Header>
        <ThemedText.Title
          color="primary1"
          textAlign="center"
          mt={isMobile ? "" : "2rem"}
        >
          <Trans>Events</Trans>
        </ThemedText.Title>
        <Column>
          <ThemedText.Body textAlign={isMobile ? 'center' : 'left'}>
            <Trans>
              At GreenBLock Capital, we take pride in being a leading provider in the field of cryptocurrency mining equipment hosting services. As a company specialized in hosting services for cryptocurrency mining rigs, we strive to remain at the forefront of the industry, ensuring that our clients have access to top-notch facilities and high-quality services. Our commitment to excellence is reflected in our active participation in key events within the world of blockchain technology and cryptocurrency mining. We regularly attend conferences, symposiums, and specialized gatherings to stay updated on the latest trends, innovations, and advancements in the sector. Our highly specialized team participates in these events to stay abreast of new technologies, regulations, and best practices in mining equipment hosting
            </Trans>.
          </ThemedText.Body>
          <ThemedText.Body textAlign={isMobile ? 'center' : 'left'}>
            <Trans>
              This involvement allows us to offer our clients state-of-the-art facilities, with infrastructure that meets the highest standards of security, energy efficiency, and reliability. By attending and contributing to relevant industry events, we aim not only to expand our knowledge but also to establish strategic relationships with equipment manufacturers, software developers, and other key players in the cryptocurrency mining ecosystem. This network of contacts enables us to provide innovative solutions tailored to the changing needs of our clients, supporting their success in a competitive and ever-evolving environment
            </Trans>.
          </ThemedText.Body>
        </Column>
      </Header>
      <Wrapper>
        {!isMobile && <Gallery />}
        <Row>
          <ThemedText.LargeHeader color="primary1">
            BLOCKCHAIN LIFE 2023 | <Trans>OCTOBER</Trans> 24-25
          </ThemedText.LargeHeader>
        </Row>
        {isMobile && <Gallery />}
      </Wrapper>


      <Wrapper>
        <Row>
          <ThemedText.LargeHeader
            color="primary5"
            textAlign="center"
          >
            <Trans>BITMAIN | OMÁN</Trans>
          </ThemedText.LargeHeader>
        </Row>
        <Gallery2 />
      </Wrapper>
      {/* <Gallery2 /> */}
      {/* <Container style={{marginTop: "5rem", marginBottom: "5rem"}}>
        <YouTube videoId="NZaGfHvWTqo" opts={opts} onReady={onPlayerReady} />
      </Container> */}
    </Container>
  );
}
