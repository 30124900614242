import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { ButtonExternalLinkEmpty } from "components/Link";

import { isMobile } from "utils/userAgent";

import Partner from "assets/images/partnership/gbtlogo.png";
import MinandoLogo from "assets/images/partnership/minandovoy.png";
import { BlueCard } from "components/Card";

const Container = styled.div`
  display: grid;
  place-items: center;
  margin-top: 10rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5rem;
  `};
`;

const ResponsiveBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 2rem;
  `};
`;


export function Partnership() {
  return (
    <Container id={"partnership"}>
      <BlueCard width={isMobile ? '100%' : "980px"} padding={"40px"}>
        {isMobile && <ThemedText.Title
          color="primary1"
        >
          <Trans>Partnership</Trans>
        </ThemedText.Title>}
        <ResponsiveBox>
          {!isMobile && <ThemedText.Title
            color="primary1"
          >
            <Trans>Partnership</Trans>
          </ThemedText.Title>}
          <ButtonExternalLinkEmpty
            href="https://www.minandovoy.com/es_es/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={MinandoLogo} alt="minando-logo" width={isMobile ? 72 : ""} />
          </ButtonExternalLinkEmpty>
          <ButtonExternalLinkEmpty
            href="https://app.greenblockcapital.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Partner} alt="gbt-logo" width={isMobile ? 80 : ""} />
          </ButtonExternalLinkEmpty>
        </ResponsiveBox>
      </BlueCard>

    </Container>
  );
}
